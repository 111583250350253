import { useFilterStore } from '~/store/filterStore'
import { useProductsStore } from '~/store/productsStore'
import { useRouteStore } from '~/store/routesStore'

import { SHOP_ROUTES_NAMES } from '~/constants'

/**
 * Retrieves the category from the route's ASP parameters.
 * @param {Object} route - The route object containing ASP parameters.
 * @returns {String} The category name or an empty string if not found.
 */
const getShopRouteCategory = (route) => {
  if (!route) return ''
  const category = route.aspParams.find(([key, value]) => key === 'category')
  return category ? category[1] : ''
}

/**
 * Handles category changes by resetting product state and clearing session storage.
 * @param {Object} toRoute - The destination route object.
 * @param {Object} fromRoute - The originating route object.
 */
const handleCategoryChange = (toRoute, fromRoute) => {
  const productStore = useProductsStore()
  const { resetThrottleAll } = useOptimizations()

  if (getShopRouteCategory(toRoute) !== getShopRouteCategory(fromRoute)) {
    productStore.resetState() // Reset product state on category/base route change
    delete sessionStorage['77dShopQuery'] // Clear session storage to prevent query changes
    resetThrottleAll() // Reset throttle for all keys
  }
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.client) return
  const filterStore = useFilterStore()

  const routeStore = useRouteStore()
  const toRoute = routeStore.shopRoutes.find((x) => x.path === to.path)
  const fromRoute = routeStore.shopRoutes.find((x) => x.path === from.path)

  handleCategoryChange(toRoute, fromRoute)

  if (to.query.step === SHOP_ROUTES_NAMES.ITEM_DIAMOND_DETAILS) {
    to.query.diamond = to.query.diamond || from.query.diamond
  }

  filterStore.resetState()
})
